import React, { ReactNode, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Modal,
  Radio,
  Result,
  Row,
  Space,
  Table,
  theme,
  Typography,
} from "antd";
import Link from "antd/es/typography/Link";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { createRecurringTransaction } from "api/RT";
import DocumentArrowDownOutline from "assets/icons/document-arrow-down-outline.svg";
import BackButton from "components/layout/BackButton";
import QPageLayout from "components/layout/QPageLayout";
import {
  AddDocumentsButton,
  CustomDatePicker,
  CustomInput,
  CustomInputNumber,
  CustomSelect,
  CustomSubmitButton,
  CustomTextArea,
} from "components/tenant/recurring_transactions/CustomFormItems";
import Documents from "components/tenant/recurring_transactions/documents";
import { useDocumentsColumns, useNextReference, useRTPermissions } from "hooks/RT";
import { RecurringTransactionData, SelectedDocument } from "types/RT";

const { useToken } = theme;
const { Title } = Typography;

const NewRecurringTransactions = () => {
  const { token } = useToken();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const title = t("recurring_transactions.new");

  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [maxRuns, setMaxRuns] = useState<number>(1);
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState<boolean>(false);
  const [documentsData, setDocumentsData] = useState<SelectedDocument[]>(
    form.getFieldValue("recurring_transaction_documents_attributes") || []
  );
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState<boolean>(false);
  const [statusesArray, setStatusesArray] = useState<string[]>([]);
  const [submitButtons, setSubmitButtons] = useState<ReactNode[]>([]);

  const { isPermissionsLoading, permissions } = useRTPermissions();

  const { nextReference, refetch } = useNextReference();

  useEffect(() => {
    if (!isPermissionsLoading) {
      const statuses: string[] = [];
      const submitButtons: ReactNode[] = [];
      const canApprove = permissions?.recurring_transactions.approve;
      const canWrite = permissions?.recurring_transactions.write;

      if (canApprove) {
        submitButtons.push(
          <CustomSubmitButton key="start_process" text="start_process" redirect="index" />
        );
        if (canWrite) {
          statuses.push("draft");
          submitButtons.push(<CustomSubmitButton key="start_and_create" text="start_and_create" />);
        }
        statuses.push("approved");
      }
      if (canWrite) {
        statuses.push("awaiting_approval");
        submitButtons.push(
          <CustomSubmitButton key="save_as_draft" text="save_as_draft" status="draft" />
        );
      }

      setStatusesArray((prevStatuses) => [...prevStatuses, ...statuses]);
      setSubmitButtons((prevStatuses) => [...prevStatuses, ...submitButtons]);
    }
  }, [isPermissionsLoading, permissions]);

  const onStartDateChange = (selectedDate: Dayjs) => {
    if (selectedDate < dayjs(startDate)) {
      form.setFieldValue("end_date", "");
    }
    setStartDate(selectedDate);
  };

  const onFrequencyChange = (value: string) => {
    let runs: number;
    switch (value) {
      case "daily":
        runs = 366;
        break;
      case "weekly":
        runs = 52;
        break;
      case "monthly":
        runs = 12;
        break;
      case "quarterly":
        runs = 4;
        break;
      case "semi_annually":
        runs = 2;
        break;
      default:
        runs = 1;
    }
    setMaxRuns(runs);
  };

  const handleOpenDocuments = () => {
    setIsDocumentsModalOpen(true);
  };

  const handleSaveDocumentsSelection = (documents: SelectedDocument[]) => {
    setDocumentsData(documents);
    form.setFieldValue("recurring_transaction_documents_attributes", documents);
  };

  const handleDeleteDocument = (id: number) => {
    setDocumentsData((prevDocuments) => prevDocuments.filter((document) => document.id !== id));
    form.setFieldValue("recurring_transaction_documents_attributes", documentsData);
  };

  const modalCloseHandler = () => {
    setIsDocumentsModalOpen(false);
  };

  const columns = useDocumentsColumns({ handleDeleteDocument });

  const handleResetForm = () => {
    setDocumentsData([]);
    form.setFieldValue("recurring_transaction_documents_attributes", []);
    form.resetFields();
    refetch();
  };

  useEffect(() => {
    if (nextReference) {
      form.setFieldValue("reference", nextReference);
    }
  }, [nextReference]);

  const mutation = useMutation(createRecurringTransaction, {
    onSuccess: (data) => {
      if (form.getFieldValue("redirect") === "index") {
        window.location.replace("/tenant/recurring_transactions");
      }
      handleResetForm();
    },
    onError: (error: {
      response?: { data?: { error?: { field: string; message: string }[] } };
    }) => {
      if (error.response?.data?.error) {
        const errors = error.response.data.error;
        const fieldErrors = Object.keys(errors).map((field) => ({
          name: field,
          errors: errors[field],
        }));
        form.setFields(fieldErrors);
      } else {
        console.error("An unexpected error occurred:", error);
      }
    },
  });

  const onFinish = (values: RecurringTransactionData) => {
    const documentType = documentsData[0]?.type.slice(0, -1);
    const recurredIds = documentsData.map((document: SelectedDocument) => ({
      documentable_id: document.id,
    }));

    const data: RecurringTransactionData = {
      ...values,
      document_type: documentType as "Bill" | "Invoice" | "SimpleBill",
      recurring_transaction_documents_attributes: recurredIds,
      status: form.getFieldValue("status"),
    };
    mutation.mutate(data);
  };

  const onReset = () => {
    if (
      form.isFieldsTouched() ||
      form.getFieldValue("recurring_transaction_documents_attributes").length > 0
    ) {
      setIsCancellationModalOpen(true);
    }
  };

  const handleCloseCancellationModal = () => {
    setIsCancellationModalOpen(false);
  };

  const handleCancellation = () => {
    handleResetForm();
    handleCloseCancellationModal();
  };

  const handleSave = () => {
    form.submit();
    handleCloseCancellationModal();
  };

  let DocumentContent = (
    <Card>
      <Title level={5}>{t("recurring_transactions.create.documents.title")}</Title>
      <Row justify="center" align="middle">
        <Col lg={22} xl={16} xxl={11}>
          <Result
            icon={<DocumentArrowDownOutline fontSize={32} />}
            title={t("recurring_transactions.create.documents.no_documents")}
            extra={<AddDocumentsButton handleOpenDocuments={handleOpenDocuments} />}
          />
        </Col>
      </Row>
    </Card>
  );

  if (documentsData && documentsData.length > 0) {
    DocumentContent = (
      <Card>
        <Row gutter={[token.padding, token.padding]} justify="space-between" align="top">
          <Col>
            <Title level={5} style={{ marginTop: token.marginXXS }}>
              {t("recurring_transactions.create.documents.title")}
            </Title>
          </Col>
          <Col>
            <Space wrap align="start">
              <AddDocumentsButton handleOpenDocuments={handleOpenDocuments} />
              <CustomSelect
                name="document_status"
                optionsArray={statusesArray}
                optionsParent="status"
              />
            </Space>
          </Col>
        </Row>
        <Form.Item noStyle>
          <Table
            columns={columns}
            dataSource={documentsData}
            virtual
            scroll={{ x: 1000, y: 260 }}
            rowKey="id"
            style={{ paddingTop: token.paddingXL, paddingInline: token.paddingXS }}
          />
        </Form.Item>
      </Card>
    );
  }

  return (
    <QPageLayout
      title={title}
      breadCrumbData={[
        { title: <Link href="#">{t("activerecord.attributes.layout.accounting")}</Link> },
        { title: <Link onClick={window.go_back}>{t("recurring_transactions.title")}</Link> },
        { title: title },
      ]}
      actionBtns={<BackButton />}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" scrollToFirstError>
        <Space direction="vertical" size={token.paddingLG} style={{ display: "flex" }}>
          <Card>
            <Row justify="space-between">
              <Col xs={24} lg={11}>
                <Title level={5}>{t("recurring_transactions.create.main_info.title")}</Title>
                <Row gutter={[token.padding, token.padding]}>
                  <Col xs={24} sm={12}>
                    <CustomInput
                      name="reference"
                      label={t("recurring_transactions.create.main_info.reference")}
                    />
                  </Col>
                </Row>
                <Row gutter={[token.padding, token.padding]}>
                  <Col xs={24} sm={12}>
                    <CustomInput
                      name="ar_name"
                      label={t("recurring_transactions.create.main_info.ar_name")}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <CustomInput
                      name="en_name"
                      label={t("recurring_transactions.create.main_info.en_name")}
                    />
                  </Col>
                </Row>
              </Col>
              <Divider type="vertical" style={{ height: "auto" }} />
              <Col xs={24} lg={11}>
                <Title level={5}>{t("recurring_transactions.create.details.title")}</Title>
                <Row gutter={[token.padding, token.padding]}>
                  <Col xs={24} sm={12}>
                    <CustomDatePicker
                      name="start_date"
                      label={t("recurring_transactions.create.details.start_date")}
                      minDate={dayjs().startOf("day")}
                      onChange={onStartDateChange}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <CustomSelect
                      name="repeat_term"
                      label={t("recurring_transactions.create.details.repeat_term")}
                      optionsArray={["daily", "weekly", "monthly", "quarterly", "semi_annually"]}
                      optionsParent="recurring_transactions.create.details.repeat_terms"
                      onChange={onFrequencyChange}
                    />
                  </Col>
                </Row>
                <Row gutter={[token.padding, token.padding]}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="end_type"
                      label={t("recurring_transactions.create.details.ends.label")}
                      layout="horizontal"
                      colon={false}
                      labelCol={{
                        style: { maxHeight: "27px", display: "flex", alignItems: "center" },
                      }}
                      initialValue="runs_count"
                    >
                      <Radio.Group>
                        <Space direction="vertical" size={token.padding}>
                          <Radio value="custom">
                            {t("recurring_transactions.create.details.ends.on_custom_date")}
                          </Radio>
                          <Radio value="runs_count">
                            {t("recurring_transactions.create.details.ends.after_max_runs")}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.end_type !== currentValues.end_type
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("end_type") === "custom" ? (
                          <CustomDatePicker
                            name="end_date"
                            label={t("recurring_transactions.create.details.end_date")}
                            minDate={dayjs(startDate)}
                            maxDate={dayjs(startDate).add(1, "year")}
                          />
                        ) : (
                          <CustomInputNumber
                            name="total_runs"
                            label={t("recurring_transactions.create.details.total_runs")}
                            suffix={t("recurring_transactions.create.details.runs")}
                            min={1}
                            max={maxRuns}
                          />
                        )
                      }
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row justify="space-between">
              <Col xs={24} lg={11}>
                <Title level={5}>{t("recurring_transactions.create.more_info.title")}</Title>
                <CustomTextArea
                  name="description"
                  label={t("recurring_transactions.create.more_info.description")}
                />
              </Col>
              <Divider type="vertical" style={{ height: "auto" }} />
              <Col xs={24} lg={11} style={{ alignSelf: "end" }}>
                <CustomTextArea
                  name="user_comments"
                  label={t("recurring_transactions.create.more_info.additional_comments")}
                />
              </Col>
            </Row>
          </Card>
          {DocumentContent}
          <Form.Item name="recurring_transaction_documents_attributes" noStyle/>
          <Space size="small" wrap style={{ display: "flex", justifyContent: "end" }}>
            {submitButtons.map((button) => button)}
            <Form.Item>
              <Button htmlType="button" onClick={onReset}>
                {t("recurring_transactions.actions.cancel")}
              </Button>
            </Form.Item>
            {isCancellationModalOpen && (
              <Modal
                open={isCancellationModalOpen}
                closable
                centered
                onCancel={handleCloseCancellationModal}
                footer={
                  <Flex justify="end" gap={token.margin}>
                    <Button type="primary" onClick={handleSave}>
                      {t("recurring_transactions.actions.save")}
                    </Button>
                    <Button onClick={handleCancellation}>
                      {t("recurring_transactions.actions.confirm_cancel")}
                    </Button>
                  </Flex>
                }
                style={{ textAlign: "center" }}
              >
                <CloseCircleOutlined style={{ fontSize: 48, color: "#cf1322" }} />
                <Title level={4}>
                  {t("recurring_transactions.create.cancel_confirmation.title")}
                </Title>
                <p>{t("recurring_transactions.create.cancel_confirmation.sub_title")}</p>
                <Divider />
              </Modal>
            )}
          </Space>
        </Space>
      </Form>
      <Documents
          onClose={modalCloseHandler}
          isOpen={isDocumentsModalOpen}
          onSave={handleSaveDocumentsSelection}
        />
    </QPageLayout>
  );
};

export default NewRecurringTransactions;
