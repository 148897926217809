import React, { useMemo } from "react";
import {
  Button,
  Flex,
  Space,
  Table,
  TableProps,
  theme,
  Typography,
} from "antd";
import { ScheduleData, ScheduleType, TransactionDocumentType } from "types/RT";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Status from "./Status";
import {
  CaretRightOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useRTPermissions } from "hooks/RT";

interface Props {
  schedulesData: ScheduleData[];
  isLoading: boolean;
  type: ScheduleType;
  totalEntries: number | undefined;
  pageSize: number | undefined;
  onChange: TableProps["onChange"];
  anyFailed?: boolean;
  currentPage?: number;
  documentType?: TransactionDocumentType;
  onSkip: (id: number) => void;
  onStart: (id: number) => void;
}

const translationTerms = {
  title: {
    [ScheduleType.UPCOMMING]:
      "recurring_transactions.show.schedules.scheduled_title",
    [ScheduleType.PASSED]:
      "recurring_transactions.show.schedules.history_title",
  },
  columns: {
    reference: "activerecord.external_documents.bill.reference",
    name: "recurring_transactions.show.schedules.name",
    created_at: "recurring_transactions.show.schedules.created_date",
    issueDate: "activerecord.external_documents.invoice.issue_date",
    dateNextRun: "recurring_transactions.show.date_next_run",
    status: "recurring_transactions.show.status",
  },
};

export default function SchedulesTable({
  isLoading,
  schedulesData,
  type,
  pageSize,
  totalEntries,
  onChange,
  anyFailed,
  currentPage,
  documentType,
  onSkip,
  onStart,
}: Props) {
  const { t } = useTranslation();

  const { token } = theme.useToken();

  const columns = useColumns({
    type,
    anyFailed,
    documentType,
    currentPage,
    onSkip,
    onStart,
  });

  const dataSource = useMemo<typeof schedulesData>(() => {
    return schedulesData?.map((record) => ({
      ...record,
      documents: record.documents.length > 0 && record.documents,
    }));
  }, [schedulesData]);

  return (
    <Flex vertical>
      <Typography.Title level={4} style={{ marginBottom: token.marginLG }}>
        {t(translationTerms.title[type])}
      </Typography.Title>

      <Table<ScheduleData>
        onChange={onChange}
        rowKey={"reference"}
        expandable={{
          childrenColumnName: "documents",
          showExpandColumn: type === ScheduleType.PASSED,
        }}
        pagination={{
          total: totalEntries,
          pageSize: pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        dataSource={dataSource}
        loading={isLoading}
        columns={columns}
      />
    </Flex>
  );
}

const useColumns = ({
  anyFailed,
  documentType,
  onSkip,
  onStart,
  type,
  currentPage,
}: {
  type: ScheduleType;
  anyFailed: boolean;
  documentType: TransactionDocumentType | undefined;
  onSkip: (id: number) => void;
  onStart: (id: number) => void;
  currentPage?: number;
}): TableProps<ScheduleData>["columns"] => {
  const { t } = useTranslation();

  const { permissions, isPermissionsLoading } = useRTPermissions();

  const documentTypeKey =
    documentType &&
    (documentType === "Bill" || documentType === "Invoice"
      ? documentType.toLocaleLowerCase() + "s"
      : "simple_bills");

  const hasWritePersmission =
    documentTypeKey &&
    !isPermissionsLoading &&
    permissions.recurring_transactions.write &&
    permissions[documentTypeKey].write;

  return [
    {
      dataIndex: "reference",
      key: "reference",
      title: t(translationTerms.columns.reference),
    },
    {
      dataIndex: "created_at",
      key: "created_at",
      title: t(translationTerms.columns.created_at),
      render(value) {
        return value && dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      dataIndex:
        type === ScheduleType.UPCOMMING
          ? "scheduled_run_date"
          : "actual_run_date",
      title:
        type === ScheduleType.UPCOMMING
          ? t(translationTerms.columns.dateNextRun)
          : t(translationTerms.columns.issueDate),
      key: "run_date",
      render(value) {
        return value && dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      dataIndex: "status",
      title: t(translationTerms.columns.status),
      render(value) {
        return value && <Status renderAsTag status={value} />;
      },
    },
    {
      title: "actions",
      render(_, record, index) {
        const isParentRow = record.documents !== undefined;

        const shouldHideActions =
          isParentRow &&
          ((type === ScheduleType.UPCOMMING && anyFailed) ||
            (type === ScheduleType.UPCOMMING && currentPage > 1) ||
            (type === ScheduleType.UPCOMMING && index > 0) ||
            (type === ScheduleType.PASSED && record.status === "success") ||
            (type === ScheduleType.PASSED && record.status === "skipped"));

        if (shouldHideActions) return null;

        return isParentRow ? (
          hasWritePersmission && (
            <Space>
              <Button
                icon={<CaretRightOutlined />}
                onClick={() => onStart(record.id)}
              />
              <Button
                icon={<CloseOutlined />}
                onClick={() => onSkip(record.id)}
              />
            </Space>
          )
        ) : (
          <Button
            icon={<EyeOutlined />}
            href={`/tenant/${documentTypeKey}/${record.id}`}
          />
        );
      },
    },
  ];
};
