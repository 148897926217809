import React from "react";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const RT_VALIDATIONS = "recurring_transactions.create.validation";
const RT_PLACEHOLDERS = "recurring_transactions.create.placeholder";

interface AddDocumentsButtonProps {
  handleOpenDocuments: () => void;
}

export const AddDocumentsButton = ({ handleOpenDocuments }: AddDocumentsButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button type="primary" onClick={handleOpenDocuments}>
      <PlusOutlined /> {t("recurring_transactions.create.documents.add_documents")}
    </Button>
  );
};

interface CustomDatePickerProps extends DatePickerProps {
  name: string;
  label: string;
}

export const CustomDatePicker = ({ name, label, ...props }: CustomDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: t(`${RT_VALIDATIONS}.${name}`) }]}
    >
      <DatePicker
        placeholder={t(`${RT_PLACEHOLDERS}.${name}`)}
        style={{ width: "100%" }}
        {...props}
      />
    </Form.Item>
  );
};

interface CustomInputProps extends InputProps {
  name: string;
  label: string;
}

export const CustomInput = ({ name, label, ...props }: CustomInputProps) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: t(`${RT_VALIDATIONS}.${name}`) }]}
    >
      <Input {...props} />
    </Form.Item>
  );
};

interface CustomInputNumberProps extends InputNumberProps {
  name: string;
  label: string;
}

export const CustomInputNumber = ({ name, label, ...props }: CustomInputNumberProps) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: t(`${RT_VALIDATIONS}.${name}`) }]}
    >
      <InputNumber style={{ width: "100%" }} {...props} />
    </Form.Item>
  );
};

interface CustomSelectProps {
  name: string;
  label?: string;
  optionsArray: string[];
  optionsParent: string;
  onChange?: (value: string) => void;
}

export const CustomSelect = ({
  name,
  label = "",
  optionsArray,
  optionsParent,
  ...props
}: CustomSelectProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: t(`${RT_VALIDATIONS}.${name}`) }]}
    >
      <Select placeholder={t(`${RT_PLACEHOLDERS}.${name}`)} allowClear {...props}>
        {optionsArray.map((option: string) => (
          <Option value={option} key={option}>
            {t(`${optionsParent}.${option}`)}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

interface CustomSubmitButtonProps {
  status?: string;
  redirect?: string | null;
  text: string;
}

export const CustomSubmitButton = ({
  status = "scheduled",
  redirect = null,
  text,
}: CustomSubmitButtonProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  return (
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => {
          form.setFieldValue("status", status);
          form.setFieldValue("redirect", redirect);
        }}
      >
        {t(`recurring_transactions.actions.${text}`)}
      </Button>
    </Form.Item>
  );
};

interface CustomTextAreaProps {
  name: string;
  label: string;
}

export const CustomTextArea = ({ name, label }: CustomTextAreaProps) => (
  <Form.Item name={name} label={label}>
    <TextArea rows={3} />
  </Form.Item>
);
